.loader{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
}

.loader .circle{
    text-align: center;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: pulse 1.5s ease-in-out infinite;
    padding: 15px;
    background-color: var(--color-white);
    border-radius: 50%;
}

.circle::before{
    content: "";
    display: block;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 6px solid #3498db;
    border-color: #3498db transparent #3498db transparent;
    animation: loader 1.2s linear infinite;
}

.loader .text{
    color: #3498db;
    font-size: 18px;
    animation: blinker 1s linear infinite;
    margin-top: 20px;
}

@keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }