#verifyAccountPUM, #uploadPhotoPUM { display:none; }
#uploadPhotoMsg a { color:red; font-weight:bold; }

#recentGamesList .game{ display:inline-block; }

.entry-content {
  margin-top: 0px!important;
  position: relative;
}

h1.entry-title {
    display: none;
}

.entry-header.alignwide {
    padding: 0px;
    margin: 0;
    height: 110px;
    background-color: #1f1f1f;
    border-bottom: solid 2px #494949;
}

.site-main {
    background-attachment: fixed;
}

.fix-container br {
    display: none;
}

.welcome-user {
    font-size: .9em;
    padding-left: 15px;
    padding-top: 30px;
}

.welcome {
    color: #fff;
    padding: 15px;
    padding-bottom: 0px;
}

.welcome-user .user-name {
    color: #d32129;
    font-weight: bold;
}

.vip-info-container {
    width: 35%;
    padding: 15px;
    float: left;
}

.tier-info {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111259;
    backdrop-filter: blur(10px);
    color: #fff;
    padding: 15px;
    padding-bottom: 50px;
}

.tier-title {
    text-align: center;
    display: block;
    padding: 15px;
    letter-spacing: .1em;
}

.item-container {
    background-image: radial-gradient(95% 100% at 50% 0%,#cbcbcb40 0,#04040436 100%);
    box-shadow: 0 2px 10px #1011123d;
    margin: auto;
    max-width: 500px;
    border-radius: 10px;
    display: flex;
    margin-top: 20px;
    margin-bottom: 5px;
    position: relative;
}

.item-container .container {
    width: 100%;
    padding: 15px 0;
    line-height: 1em;
}

.vip-item-title {
    font-size: .8em;
}

.task {
    font-size: .7em;
    color: #9f9f9f;
    padding-top: 8px;
}

.percentage {
    padding: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-right: 25px;
}

.next-rank {
    display: flex;
    max-width: 500px;
    margin: auto;
    font-size: .7em;
    color: #a7a7a7;
}

.next-rank span {
    width: 100%;
    margin-top: auto;
}

.vip-name {
    text-align: right;
}

.guage {
    display: block;
    width: 100%;
    position: absolute;
    height: 100%;
    border-radius: 10px;
    pointer-events: none;
}

.account-right-container {
    width: 65%;
    padding: 15px;
    float: left;
}

.amount-info-container {
    display: flex;
    margin: 0 -10px;
}

.permonth-container {
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    border-radius: 10px;
    border: solid 1px #3e3e3e14;
    box-shadow: 0 0 15px #1011129e;
    backdrop-filter: blur(10px);
    float: left;
    color: #fff;
    font-size: .7em;
    padding: 20px;
    width: 100%;
    margin: 0 10px;
}

span.permonth-deposit {
    font-size: 1.5em;
    font-weight: bold;
}

.right-container {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111299;
    backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 30px;
    padding: 15px;
    padding-top: 40px;
}

.deposit-top-info {
    display: flex;
}


.retain-levelup-container .amount {
    font-size: 1.5em;
    color: #fff;
    font-weight: bold;
}

.retain-levelup-container {
    text-align: left;
    font-size: .8em;
    padding: 15px;
    margin-bottom: 20px;
    width: 100%;
}

.red-button {
    background-image: radial-gradient(50% 100% at 50% 142%, #dd1212 0, #870404 100%);
    box-shadow: 0 0 5px 0px #4c0000, 0 0 10px 2px #a7000087;
    padding: 6px 18px;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 0 5px #fff;
    font-size: .8em;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;
    transition: all .7s;
    display: block;
    margin: auto;
    border-width: 2px;
    margin-top: 15px;
}

.account-collapsible {
    padding: 15px;
}

.account-collapsible h3 {
    font-size: .8em;
    max-width: 700px;
    margin: auto;
    padding: 15px 0px;
    padding-left: 30px;
    cursor: pointer;
}

.account-collapsible > div {
    max-width: 700px;
    margin: auto;
}

.account-collapsible .notice {
    background-color: #00000036;
    font-size: .75em;
    padding: 15px;
    border-radius: 10px;
    color: #d3d3d3;
    margin-bottom: 15px;
}

.field-row {
    display: flex;
}

.account-collapsible label {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: #787878;
    color: #000;
    padding: 10px 15px;
    font-size: .8em;
}

.field-container {
    width: 100%;
    background-color: #444444;
    padding: 10px 15px;
}

.field-container input {
    font-size: 1em;
    padding: 5px;
    width: 100%;
    line-height: 1em;
    background: transparent;
    border: solid 1px #8b8b8b;
    color: #fff;
}

#changePasswordForm > div {
    max-width: 700px;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
}

.field-row {
    display: flex;
}

#changepwd-alert-cont{ display:none; }
#changepwd-alert {
    font-size: .8em;
    font-weight: 400;
    color: #ff5539;
    padding: 15px;
    text-align: center;
}

.deposit-guage-container {
    height: 8px;
    margin: 0px 15px;
    background-color: #bdbdbd33;
    border-radius: 15px;
}

.deposit-guage-container .guage {
    background-color: #d52c2c;
    border: solid 1px #ff2424;
    border-radius: 12px;
    box-shadow: 0 0 8px #c10000, inset 0 0 4px #ffe4e4b3;
    width: auto;
    position: unset;
    height: 100%;
}

.account-collapsible li {
    list-style: none;
  position: relative;
}

.account-collapsible .plus-minus-toggle {
    position: absolute;
    top: 22px;
    left: 5px;
}

.account-collapsible .plus-minus-toggle:before {
  background: white;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
  transition: transform 500ms ease;
  transform: scale(.9) rotate(-45deg);
}
.account-collapsible .plus-minus-toggle:after {
  background: white;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  width: 10px;
  transition: transform 500ms ease;
  transform: scale(.9) rotate(45deg);
}
.account-collapsible .plus-minus-toggle:after {
  transform-origin: center;
}
.account-collapsible .plus-minus-toggle.collapsed:after {
  transform: rotate(90deg);
}
.account-collapsible .plus-minus-toggle.collapsed:before {
  transform: rotate(-180deg);
}

.vipIcon {
    margin: auto;
    display: block;
    max-width: 260px!important;
}

.item-container .icon {
    padding-top: 6px;
}

.item-container .icon img {
    max-width: 88px;
}
.deposit-btn-mobile {
  display: none;
  text-align: center;
}

.recent-games {
    padding-top: 56px;
    clear: both;
    position: relative;
}

.recent-games h3 {
    font-size: 1.5em;
    font-weight: 600;
    color: #fff;
}

.recent-games .title-line {
    margin-bottom: 25px;
    float: left;
}

#recentGamesList {
    clear: both;
}

#recentGamesList .game {
    display: inline-block;
    width: 19%;
    margin: .5%;
    position: relative;
    /*cursor: pointer;*/
}

.game:hover img {
    filter: unset;
}
.account-navigation a.active {
    color: #fff;
    background: #fff;
    background: radial-gradient(60% 107% at 50% 118%, #8d0000 0, #101331 100%);
    border-bottom: 4px solid #a11414;
}
.featured-item {
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    border-radius: 10px;
    border: solid 1px #58585870;
    padding: 10px 0px;
    margin: 0px 5px;
    filter: drop-shadow(0px 6px 10px #111);
    width: 100%;
    text-align: center;
    color: #fff;
}

.featured-item img {
    cursor: pointer;
    transform: scale(.9) translate(0px, 0px);
    transition: all .2s;
    height: 98px;
    width: unset;
}

.alert div a { color:red; }

.display-flex {
    display: flex;
    clear: both;
    width: 100%;
}

.entry-content {
  margin-top: 0px!important;
  position: relative;
}

.red-button {
    background-image: radial-gradient(50% 100% at 50% 142%, #dd1212 0, #870404 100%);
    box-shadow: 0 0 5px 0px #4c0000, 0 0 10px 2px #a7000087;
    padding: 6px 18px;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 0 5px #fff;
    font-size: .8em;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;
    transition: all .7s;
    display: block;
    margin: auto;
    border-width: 2px;
    margin-top: 15px;
}

h1.entry-title {
    display: none;
}

.entry-header.alignwide {
    padding: 0px;
    margin: 0;
    height: 110px;
    background-color: #1f1f1f;
    border-bottom: solid 2px #494949;
}

.fix-container br {
    display: none;
}

.account-container {
    color:#fff;
    padding: 15px;
}

.report-page .account-container {
    max-width: 1180px;
    margin: auto;
    margin: 38px auto;
}

.right-container {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111299;
    backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 30px;
    padding: 15px;
    padding-top: 40px;
}

.right-container h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 80px;
    margin-bottom: 15px;
}

.deposit-container {
    max-width: 975px;
    margin: auto;
}

.depositnav-nav.nav-tabs {
    background: #ffffff30;
    background-image: radial-gradient(50% 100% at 50% 142%,#2626265c 0,#0000006b 100%);
    border-radius: 5px;
    display: flex;
    overflow: hidden;
}

.depositnav-nav.nav-tabs div {
    cursor: pointer;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 1px #dfdfdf87;
}

.depositnav-nav.nav-tabs div.active {
    background-color: #f5dd00;
}

.depositnav-nav.nav-tabs div a {
    color: #fff;
    text-decoration: none;
    font-size: .9em;
    padding: 12px;
    display: block;
}

.depositnav-nav.nav-tabs div.active a {
    color: #10101b;
    font-weight: 600;
}

.account-container form {
    border-radius: 0px 0px 5px 5px;
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    padding: 15px;
    padding-bottom: 20px;
}

.account-container .notice {
    background-color: #00000036;
    font-size: .8em;
    padding: 15px;
    border-radius: 10px;
    color: #ffe07b;
    margin-bottom: 15px;
    margin-top: 15px;
}

.account-container .row {
    margin-left: unset;
    margin-right: unset;
}

.account-container .hLabel {
    color: #dddddd;
    padding: 10px 22px;
    font-size: .9em;
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    margin-bottom: 0px!important;
    margin-top: 25px;
    text-align: center;
}

.account-container .input-container {
    background-color: #00000099;
    padding: 5px 15px;
}

.account-container input {
    background-color: transparent;
    color: #fff;
    border: solid 1px #898989;
    line-height: 1em;
    font-size: .8em;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 25px;
}

.account-container select {
    background-color: transparent;
    color: #fff;
    border: solid 1px #898989;
    line-height: 1em;
    font-size: .8em;
    text-align: center;
    width: 100%;
    padding: 10px;
    border-radius: 25px;
}

.account-container select {
    background-color: transparent;
    color: #fff;
    border: solid 1px #898989;
    line-height: 1em;
    font-size: .8em;
    text-align: center;
    width: 100%;
}

.onlinepayment-guide {
    background-image: radial-gradient(60% 100% at 50% 132%,#ededed 0,#b4b3b3 100%);
    box-shadow: 0 0 5px 0 #a3a3a3, 0 0 5px 2px #4a4a4a;
    font-size: .8em;
    font-weight: 600;
    line-height: 1em;
    display: inline-block;
    padding: 10px 15px;
    text-decoration: unset;
    border-radius: 5px;
    border: 1px;
    color: #000!important;
}

.withdraw-container {
    max-width: 975px;
    margin: auto;
}

.withdraw-nav.nav-tabs {
    background: #ffffff30;
    background-image: radial-gradient(50% 100% at 50% 142%,#2626265c 0,#0000006b 100%);
    border-radius: 5px;
    display: flex;
    overflow: hidden;
}

.withdraw-nav.nav-tabs div {
    cursor: pointer;
    width: 100%;
    text-align: center;
    box-shadow: 0 0 1px #dfdfdf87;
}

.withdraw-nav.nav-tabs div.active {
    background-color: #f5dd00;
}

.withdraw-nav.nav-tabs div a {
    color: #fff;
    text-decoration: none;
    font-size: .9em;
    padding: 12px;
    display: block;
}

.withdraw-nav.nav-tabs div.active a {
    color: #10101b;
    font-weight: 600;
}

.withdraw-nav form {
    border-radius: 0px 0px 5px 5px;
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    padding-bottom: 20px;
}

.withdraw-nav .notice {
    background-color: #00000036;
    font-size: .75em;
    padding: 15px;
    border-radius: 10px;
    color: #d3d3d3;
    margin-bottom: 15px;
    margin-top: 15px;
}

.withdraw-container form {
    border-radius: 0px 0px 5px 5px;
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    padding-bottom: 20px;
    padding-top: 15px;
}

.withdraw-container tr {
    margin: 0px 15px;
    display: flex;
    border-bottom: solid 1px #808080;
}

.withdraw-container tbody tr:first-child {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    margin-top: 40px;
}

.withdraw-container tbody tr:nth-last-child(2) {
    border-radius: 0px 0px 10px 10px;
    overflow: hidden;
}

.withdraw-container tbody tr:nth-last-child(1) {
    border: none;
}

.withdraw-container th {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    background-color: #787878;
    color: #000;
    padding: 10px 15px;
    font-size: .8em;
    font-weight: 400;
}

.withdraw-container td {
    width: 100%;
    background-color: #444444;
    padding: 10px 15px;
}

.withdraw-container select {
    padding: 10px;
    width: 100%;
    line-height: 1em;
    background: transparent;
    border: solid 1px #8b8b8b;
    color: #fff;
    font-size: .8em;
}

.withdraw-container .balance {
    font-size: .8em;
}

.bal-avatar > div {
    position: relative;
    background-color: #0006;
    box-shadow: 0 0 5px 0px #4c0000, 0 0 10px 2px #a7000087, inset 0 0 8px #9b0000;
    padding: 6px 18px;
    border-radius: 6px;
    color: #fff;
    text-shadow: 0 0 5px #fff;
    text-transform: uppercase;
    letter-spacing: .1em;
    cursor: pointer;
    transition: all .7s;
    display: block;
    margin: auto;
    border: solid 2px #851515;
    margin-top: 15px;
    text-align: center;
    max-width: 388px;
    font-size: 1.2em;
    font-weight: bold;
}

.remainingrollover {
    background-color: #ffb900;
    box-shadow: inset 0 0 8px #fff42a;
    border: solid 1px #ffc800;
    color: #000;
    padding: 5px;
    font-size: .75em;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 5px;
    border-radius: 6px;
	display:none; 
}

.smallbanner {
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 15px;
    position: relative;
}

.smallbanner img {
    display: block;
}

.right-container {
    background-image: radial-gradient(52% 65% at 50% 50%,#ededed33 0,#1c191969 100%);
    border-radius: 10px;
    border: solid 1px #ffffff14;
    box-shadow: 0 0 15px #10111299;
    backdrop-filter: blur(10px);
    color: #fff;
    width: 100%;
    float: left;
    margin-top: 30px;
    padding: 15px;
    padding-top: 40px;
}

.share-credit-tc-container {
    width: 100%;
    background-position: left bottom;
    background-size: cover;
    padding: 35px;
}

.share-credit-tc-container h3 {
    font-size: 1.2em;
}

.share-credit-tc-container h4 {
    font-size: .8em;
    margin-top: 35px;
}

.share-credit-tc-container p {
    font-size: .7em;
    margin-left: 22px;
    margin-top: 15px;
    color: #cfc6c6;
}

.share-credit-tc-container ol {
    font-size: .7em;
    margin-left: 36px;
    margin-top: 15px;
    color: #cfc6c6;
}

.share-credit-tc-container ol span {
    display: block;
    margin-left: 20px;
}

.share-credit-rq-container {
    width: 100%;
    background-color: #0000008a;
    padding: 15px;
}

.share-credit-rq-container > div {
    background-image: radial-gradient(60% 101% at 50% -24%,#ffffff3d 0,#46464682 100%);
    border: solid 1px #ffffff2e;
    box-shadow: 0 0 15px #10111259;
    height: 100%;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
    padding: 25px;
    padding-top: 30px;
}

.from-to-type-container .field-row br {
    display: none;
}

.share-credit-rq-container h3 {
    font-size: 1.2em;
}

.share-credit-rq-container form {
    margin-top: 25px;
}

.share-credit-rq-container br {
    display: none;
}

.share-credit-rq-container label {
    font-size: .7em;
}

.share-credit-rq-container input {
    margin-bottom: 15px;
    padding: 6px;
    font-size: .8em;
    width: 100%;
    border: none;
    background-color: #a5a5a533;
    border-bottom: solid 2px #8d8d8d;
    color: #fff;
}

.submit-container {
    padding: 15px;
}

.share-credit-rq-container input[type="checkbox"] {
    height: 15px;
    width: 15px;
    border: solid 1px #8d8d8d;
    margin-bottom: 0px;
    vertical-align: middle;
}

.checkbox-label {
    color: #a5a5a5;
    margin-left: 8px;
}

.share-credit-rq-container input.red-button {
    border-bottom: none;
    width: unset;
    display: inline-block;
    margin: 0px;
    padding: 10px 15px;
}

.report-info {
    text-align: center;
}

.report-info h2 {
    font-size: 1.5em;
    font-weight: 600;
    margin-top: 80px;
}

.report-request h2 {
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    margin-top: 80px;
}

.report-request p {
    font-size: .8em;
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 29px;
}

.report-request .title-line {
    float: unset;
}

p.referral-text {
    display: block;
    font-size: .8em;
    padding: 20px 0px;
}

.report-info ol {
    font-size: .75em;
    margin-left: 45px;
}

.table-fields {
    clear: both;
    background-color: #00000038;
    border: solid 1px #3e3e3e14;
    padding: 15px;
    text-align: center;
}

.from-to-type-container {
    display: inline-block;
}

.from-to-type-container .field-row {
    display: inline-block;
    padding: 0 10px;
}

.from-to-type-container label {
    display: inline-block;
    margin-bottom: 0px;
    font-size: .8em;
}

.table-fields input {
    background-color: #e3e3e3;
    color: #000;
    border: none;
    font-size: 1em;
    padding: 5px 10px;
    border-radius: 5px;
    margin-left: 0px;
}

.table-fields select {
    background-color: #e3e3e3;
    color: #000;
    border: none;
    font-size: .9em;
    padding: 5px 10px;
    padding-right: 45px;
    border-radius: 5px;
    margin-left: 0px;
}

.summary h3 {
    font-size: 1em;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: center;
}

.summary-amount {
    overflow: hidden;
    box-shadow: 0 8px 10px #00000040;
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 10px;
}

#gr-turnover {
    font-weight: bold;
}

.amount-label {
    font-size: .7em;
}

.report-detail {
    background-image: radial-gradient(50% 100% at 50% 142%,#dd1212 0,#870404 100%);
    box-shadow: 0 0 10px 0 #4c0000, 0 0 1px 1px #ef0000;
    font-size: .8em;
    border-radius: 3px;
    border: 2px;
    padding: 4px 15px;
    max-width: 146px;
    width: 100%;
    margin: auto;
    cursor: pointer;
    text-align: center;
}

.account-content table {
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 8px 10px #00000040;
}

.account-content th {
    background-image: radial-gradient(80% 82% at 50% -24%,#28282833 0,#04040475 100%);
    font-size: .8em;
    text-align: center;
    font-weight: 400;
    padding: 10px;
}

.account-content td {
    background-color: #8080804d;
    font-size: .75em;
    text-align: center;
}

#gr-summary{ display:none; }

.summary > div {
    overflow: auto;
    margin-top: 5px;
}

.summary > div table {
    min-width: 856px;
    width: 100%;
}

@media only screen and (max-width: 856px) {
    .smallbanner {
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .smallbanner img {
        scale: 1.2;
    }
  .account-navigation {
    position: relative;
    float: unset;
    max-width: unset;
    padding-top: 0px;
  }
  .fix-container {
    position: relative;
    display: flex;
    overflow: auto;
    max-width: unset;
  }
  .account-navigation a {
    width: 100%;
    white-space: nowrap;
    padding: 22px 30px;
  }
  .account-content {
    width: 100%;
    padding: 0px;
  }
  .depositnav-nav.nav-tabs div {
    line-height: 1em;
  }
  .depositnav-nav.nav-tabs {
    overflow: auto;
  }
  .right-container h2 {
    margin-top: 0px;
    font-size: 1.2em;
  }
  .withdraw-nav.nav-tabs {
    overflow: auto;
  }
  .withdraw-nav.nav-tabs div {
    line-height: 1em;
  }
  .withdraw-container td {
    display: block;
  }
  .withdraw-container tr {
    display: block;
    clear: both;
  }
  .account-navigation a.current-page {
    background: radial-gradient(60% 100% at 50% 120%,#5a3838 0,#4e4e4e2e 100%);
    border-bottom: 4px solid #a11414;
    border-left: none ;
  }
}

@media only screen and (max-width: 1458px) {
  .vip-info-container {
    width: 45%;
  }
  .account-right-container {
    width: 55%;
  }
  #recentGamesList .game {
    width: 23%;
    margin: 1%;
    }
    .amount-info-container {
        flex-direction: column;
        margin: 0px;
    }
    .permonth-container {
        margin: 10px 0px;
    }
}

@media only screen and (max-width: 1280px) {
  .vip-info-container {
    width: 100%;
  }
  .tier-avatar-container {
    width: 50%;
    float: left;
  }
  .divider-1 {
    margin-top: 55px;
  }
  .account-right-container {
    width: 100%;
  }
  .header-spacer {
    height: 64px;
  }
    .permonth-container {
        margin: 0 10px;
    }
    .amount-info-container {
        flex-direction: inherit;
        margin: 0 -10px;
    }
}

@media only screen and (max-width: 856px) {
    .featured-item img {
        width: 100%;
        max-width: 58px;
    }
    .featured-item {
        font-size: .6em;
        margin: 2px;
        min-width: 107px;
        padding: 5px 0px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        transform: unset;
    }
    #recentGamesList .gameImg:after {
        display: none;
    }
    #recentGamesList {
        text-align: center;
    }
  .tier-avatar-container {
      width: 100%;
      float: unset;
  }
  .account-navigation {
    position: relative;
    float: unset;
    max-width: unset;
    padding-top: 0px;
  }
  .fix-container {
    position: relative;
    display: flex;
    overflow: auto;
    max-width: unset;
  }
  .account-navigation a {
    width: 100%;
    white-space: nowrap;
    padding: 22px 30px;
  }
  .item-container .icon img {
    max-width: 65px;
  }
  .item-container .icon {
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .vip-item-title {
    font-size: .7em;
  }
  .item-container .icon {
    padding-top: 0px;
  }
  .percentage {
    padding-right: 15px;
  }
  .vipIcon {
    max-width: 200px!important;
  }
  .deposit-top-info {
    display: unset;
  }
  .retain-levelup-container {
    margin-bottom: 0px;
  }
  .deposit-top-info .red-button {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 28px;
  }
  .right-container {
    padding-top: 15px;
  }
  .field-row {
    display: block;
  }
  .deposit-btn {
    display: none;
  }
  .deposit-btn-mobile {
    display: block;
  }
  .account-navigation a.current-page {
    background: radial-gradient(60% 100% at 50% 120%,#5a3838 0,#4e4e4e2e 100%);
    border-bottom: 4px solid #a11414;
    border-left: none;
  }
}

@media only screen and (max-width: 657px) {
    .amount-info-container {
        flex-direction: column;
        margin: 0px;
    }
    .permonth-container {
        margin: 10px 0px;
    }
}

.permonth-container img {
    margin-bottom: 8px;
}

.featured-icons {
    background: unset;
    clear: both;
}

.featured-icons:before {
    display: none;
}

.icons-container {
    padding: unset;
    padding-top: 0px;
    clear: both;
    display: flex;
}
.bottom-text {
    color: white;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
}

a.info-icon-link {
    color: #c5c5c5;
    font-size: 1.5em;
    min-width: 28px;
    min-height: 28px;
    display: inline-block;
    text-align: center;
    background-color: #202020;
    border: solid 1px #606060;
    border-radius: 18px;
    box-shadow: 0 0 8px 2px #323232, inset 0 0 8px #686868c7;
    line-height: 1.2em;
    margin-left: 8px;
    transition: all .2s;
    text-decoration: none;
}
a.info-icon-link:hover {
    color: #ffffff;
    background-color: #383838;
    box-shadow: 0 0 8px 2px #7e7e7e, inset 0 0 8px #686868c7;
}
